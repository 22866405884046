import { render, staticRenderFns } from "./serveSix.vue?vue&type=template&id=561f6d16&scoped=true&"
import script from "./serveSix.vue?vue&type=script&lang=js&"
export * from "./serveSix.vue?vue&type=script&lang=js&"
import style0 from "./serveSix.vue?vue&type=style&index=0&id=561f6d16&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561f6d16",
  null
  
)

export default component.exports