import { render, staticRenderFns } from "./serveTwo.vue?vue&type=template&id=99f51544&scoped=true&"
import script from "./serveTwo.vue?vue&type=script&lang=js&"
export * from "./serveTwo.vue?vue&type=script&lang=js&"
import style0 from "./serveTwo.vue?vue&type=style&index=0&id=99f51544&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f51544",
  null
  
)

export default component.exports